import { ModuleComponent, ModulesCompleted } from "~/components";
import type { LocationState } from "~/types";
import { Meta } from "~/ui";

import {
  moduleOneQuestions,
  moduleTwoQuestions,
  moduleThreeQuestions,
  moduleFourQuestions,
  ModuleVideos,
  ModuleData,
  ModuleReflections,
  Routes,
} from "~/models";

export default function Modules({
  location,
}: {
  location: LocationState;
}): JSX.Element | null {
  const currentModule = location.state?.moduleSelection || 1;

  switch (currentModule) {
    case 1: {
      return (
        <ModuleComponent
          moduleNumber={1}
          moduleTitle="An Introduction to Volunteering"
          moduleIntroduction="Learn about what volunteering is and why people volunteer."
          questions={moduleOneQuestions}
          moduleReflection={ModuleReflections.ModuleOne}
          moduleStorageName={ModuleData.ModuleOne}
          streamVideo={{
            id: ModuleVideos.ModuleOne,
            title: "Module 1 Video - An Introduction to Volunteering",
            posterUrl: `https://videodelivery.net/${ModuleVideos.ModuleOne}/thumbnails/thumbnail.jpg?time=25s&height=900`,
          }}
        />
      );
    }
    case 2: {
      return (
        <ModuleComponent
          moduleNumber={2}
          moduleTitle="Types of Volunteering"
          moduleIntroduction="Types of volunteering and finding your reason to volunteer."
          questions={moduleTwoQuestions}
          moduleReflection={ModuleReflections.ModuleTwo}
          moduleStorageName={ModuleData.ModuleTwo}
          streamVideo={{
            id: ModuleVideos.ModuleTwo,
            title: "Module 2 Video - Types of Volunteering",
            posterUrl: `https://videodelivery.net/${ModuleVideos.ModuleTwo}/thumbnails/thumbnail.jpg?time=18s&height=900`,
          }}
        />
      );
    }
    case 3: {
      return (
        <ModuleComponent
          moduleNumber={3}
          moduleTitle="Finding Volunteer Opportunities"
          moduleIntroduction="Searching for roles and background checks you might need."
          questions={moduleThreeQuestions}
          moduleReflection={ModuleReflections.ModuleThree}
          moduleStorageName={ModuleData.ModuleThree}
          streamVideo={{
            id: ModuleVideos.ModuleThree,
            title: "Module 3 Video - Finding Volunteer Opportunities",
            posterUrl: `https://videodelivery.net/${ModuleVideos.ModuleThree}/thumbnails/thumbnail.jpg?time=25s&height=900`,
          }}
        />
      );
    }
    case 4: {
      return (
        <ModuleComponent
          moduleNumber={4}
          moduleTitle="Rights and Responsibilities"
          moduleIntroduction="Your rights and responsibilities and taking the next steps to volunteering."
          questions={moduleFourQuestions}
          moduleReflection={ModuleReflections.ModuleFour}
          moduleStorageName={ModuleData.ModuleFour}
          streamVideo={{
            id: ModuleVideos.ModuleFour,
            title: "Module 4 Video - Rights and Responsibilities",
            posterUrl: `https://videodelivery.net/${ModuleVideos.ModuleFour}/thumbnails/thumbnail.jpg?time=25s&height=900`,
          }}
        />
      );
    }
    case "finished": {
      return <ModulesCompleted />;
    }
  }
}

export const Head = () => {
  return <Meta title="Modules" url={Routes.Modules} />;
};
